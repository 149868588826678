import styled from 'styled-components';

export const Wrapper = styled.div`
  max-width: var(--maxWidth);
  margin: 0 auto;
  padding: 0 20px;
  
  h1 {
    color: var(--medGrey);
  }

  @media screen and (max-width: 700px) {
    font-size: 1.7rem;
  }
`;


export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 2rem;
`;